// External
import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Internal
import UserInformationStorage from 'general_utils/userinformationstorage.js';
import { Loading } from 'general_components';
import { validateToken } from 'general_utils/validateToken.js';
import { loginUser, WegawLogo, FormInput } from './utils/utils.js';

// Style
import'./Login.scss';

function Login({ setUserId }) {

    const [isValidToken, setIsValidToken] = useState(null);
    let navigate = useNavigate();

    useEffect(() => {
        const token = UserInformationStorage.get_user_information("token");
        const checkTokenValidity = async () => {
            try {
                if (token) {
                    const isValid = await validateToken(token);
                    if (isValid) {
                        navigate('/dashboard');
                    } else {
                        UserInformationStorage.clear_user_information();
                        setIsValidToken(isValid);
                    } 
                } else {
                    UserInformationStorage.clear_user_information();
                    setIsValidToken(false);
                }
            } catch (error) {
                console.error('Error during token validation:', error);
            }
        };
        checkTokenValidity();
    }, [navigate]);

    const handleSubmit = async event => {
        //Prevent page reload
        event.preventDefault();
        let { username, password } = document.forms[0];
        const accessAndRefreshToken = await loginUser({ username, password });

        if (accessAndRefreshToken.access) {
            UserInformationStorage.set_user_information('user', username.value)
            setUserId(username.value);
            UserInformationStorage.set_user_information('token', accessAndRefreshToken.access);
            UserInformationStorage.set_user_information('refreshToken', accessAndRefreshToken.refresh);
            navigate('/dashboard');
        } else if (accessAndRefreshToken.detail) {
            alert(accessAndRefreshToken.detail)
        }
    };

    if (isValidToken === false) {
        return (
            <Container className="jumbotron d-flex align-text-center align-items-center justify-content-center min-vh-100">
                <Form id="sign-in-form" className="text-center" onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <WegawLogo/>
                    </div>
                    <FormInput
                        id="formUsername"
                        loginInformation={'username'}
                    />
                    <FormInput
                        id="formPassword"                    
                        loginInformation={'password'}
                    />
                    <Form.Group className="mb-3">
                        <Button 
                            variant='wegaw-blue'
                            type="submit">
                                Login
                        </Button>
                    </Form.Group>
                </Form>
            </Container>
    )
    } else {
        return (
            <Container className="jumbotron d-flex align-items-center justify-content-center min-vh-100">
                <Loading/>
            </Container>
        )
    }

}

export default Login;