// External
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { ButtonGroup, Row, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { FaFilter } from 'react-icons/fa';

// Internal
import { createCheckboxToogleButtonsFromArrOfDict, createRadioToogleButtonsFromArrOfDict, datesDict } from './../utils/utils';
import { DateSelector, DownloadCsvButton, ListOfProductsContext, TooltipCustom } from 'general_components';
import { general_config } from 'general_utils/general_configuration';
import { axiosCustom } from 'general_utils/axios';
import DateManager from 'general_utils/datemanager';
import { productResponseToArrays } from 'general_utils/functions';

function MultiFilterCsvDownloader({ catchment }) {

    const datemanager = new DateManager();

    const [radioDateValue, setRadioDateValue] = useState("1m");

    const [modalShow, setModalShow] = useState(false);
    const modalHandleClose = () => setModalShow(false);
    const modalHandleShow = () => setModalShow(true);

    const [productNames, setProductNames] = useState([general_config.default_product]);
    const [apiData, setApiData] = useState([]);
    const [selectedTo, setSelectedTo] = useState(datemanager.ISOStringToDate(new Date().toISOString()));
    const [selectedFrom, setSelectedFrom] = useState(datemanager.ISOStringToDate(datemanager.unixTimestampToDatetime(datemanager.substractDaysToUnixTimestamp(new Date(), 30)).toISOString()));

    const listOfProducts = useContext(ListOfProductsContext);
    const productsDict = listOfProducts.map(item => {
        return {
          tag: item.toUpperCase(),
          product: item
        };
      });


    useEffect(() => {
        if (selectedTo && selectedFrom) {
            let axiosRequests = [];

            for (let indexProduct in productNames) {
                axiosRequests.push(
                    axiosCustom(`catchments/${catchment}/${productNames[indexProduct].toLocaleLowerCase()}-stats/?to=${selectedTo}&from=${selectedFrom}`)
                )
            }

            axios.all(axiosRequests)
                .then(axios.spread((...responses) => {
                    let apiDataObj = []
                    for (let responseIndex in responses) {
                        let [dates, values] = productResponseToArrays(responses[responseIndex])
                        apiDataObj.push(
                            {
                                x: dates,
                                y: values,
                            },
                        )
                    }
                    setApiData(apiDataObj)
                }))
        }
    }, [selectedTo, selectedFrom, productNames]);

    const handleChange = (parameter) => {
        setProductNames(parameter);
    };

    const onRadioToogleButtonClick = (element) =>{
        const datemanager = new DateManager();
        let [to, from] = datemanager.getToFrom(element.days)
        setSelectedTo(to);
        setSelectedFrom(from);
    }

    return (
        <Row>
            <ButtonGroup className='btn-toolbar mb-2'>
                {   
                    createRadioToogleButtonsFromArrOfDict(
                        datesDict, 
                        'datesFilter', 
                        radioDateValue, 
                        setRadioDateValue, 
                        onRadioToogleButtonClick,
                        'graph'
                    )
                }
                <TooltipCustom
                    placement={'bottom'}
                    text={"Select custom dates."}>
                    <ToggleButton
                        id={`radio-datesFilter`}
                        type="radio"
                        variant='graph'
                        name="radio"
                        value={'datesFilter'}
                        checked={radioDateValue === 'datesFilter'}
                        onClick={() => {
                            modalHandleShow()
                            setRadioDateValue("datesFilter")
                        }}
                    >
                        <FaFilter/>
                    </ToggleButton>
                </TooltipCustom>
                <DateSelector
                    modalShow={modalShow} 
                    modalHandleClose={modalHandleClose} 
                    setSelectedTo={setSelectedTo} 
                    setSelectedFrom={setSelectedFrom}
                />
                <DownloadCsvButton
                    apiData={apiData}
                    catchment={catchment}
                    productNames={productNames}
                    selectedTo={selectedTo}
                    selectedFrom={selectedFrom}
                    buttonVariant={'graph'}
                />
            </ButtonGroup>
            <TooltipCustom
                placement={'bottom'}
                text={"Select the products to download."}>
                <ToggleButtonGroup className='btn-toolbar mb-2' type="checkbox" onChange={handleChange} value={productNames}>
                    { 
                        createCheckboxToogleButtonsFromArrOfDict(
                            productsDict, 
                            'products'
                        )
                    }
                </ToggleButtonGroup>
            </TooltipCustom>
        </Row>
    );
};

export default MultiFilterCsvDownloader;