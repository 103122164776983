// External
import { useState } from 'react';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from 'react-datepicker';

// Internal
import DateManager from "general_utils/datemanager";

// Style
import styles from "./DateSelector.module.scss";

function DateSelector({ modalShow, modalHandleClose, setSelectedTo, setSelectedFrom}) {

    const datemanager = new DateManager();

    const [showTo, setShowTo] = useState(null);
    const [showFrom, setShowFrom] = useState(null);
    const [tmpTo, setTmpTo] = useState();
    const [tmpFrom, setTmpFrom] = useState();

    const dateFormat = "yyyy-MM-dd";
    const cleanDates = () => {                      
        setShowTo("");
        setShowFrom(""); 
    }

    return (
        <Modal show={modalShow}
            onHide={modalHandleClose}>
            <Modal.Header className={styles.modal_header}>
                <h3>Select dates</h3>
            </Modal.Header>
            <Modal.Body>
                <Row className={styles.input_dates_container}>
                    <Col>
                        <p>From date {dateFormat} <FaCalendarAlt/></p>
                        <DatePicker
                            dateFormat={dateFormat}
                            showYearDropdown
                            selected={showFrom}
                            onChange={(fromStringDatetime) => {
                                setShowFrom(fromStringDatetime);
                                setTmpFrom(datemanager.transformStringDatetimeToDate(fromStringDatetime));
                            }}
                        />
                
                    </Col>
                    <Col>
                        <p>To date {dateFormat} <FaCalendarAlt/></p>
                        <DatePicker
                            dateFormat={dateFormat}
                            showYearDropdown
                            selected={showTo}
                            onChange={(toStringDatetime) => {
                                setShowTo(toStringDatetime);
                                setTmpTo(datemanager.transformStringDatetimeToDate(toStringDatetime));
                            }
                            }
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Button variant='secondary' className={styles.modal_button}
                            onClick={() => {
                                setSelectedTo(tmpTo);
                                setSelectedFrom(tmpFrom);
                                modalHandleClose();
                            }
                            }>
                            Search
                        </Button>
                        <Button variant='secondary' className={styles.modal_button}
                            onClick={() => {
                                cleanDates();
                            }}
                        >
                            Clean Dates
                        </Button>
                    </Row>
            </Modal.Body>
        </Modal>
    )
}

export default DateSelector;