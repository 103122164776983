// External
import { Button } from 'react-bootstrap';
import { FaFileImage } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import { GrMapLocation } from "react-icons/gr";

// Internal
import { TooltipCustom } from './';
import UserInformationStorage from 'general_utils/userinformationstorage';

const emptyCatchment = () => {
    UserInformationStorage.remove_user_information('catchmentGeoJson');
}

function BackToGraphsButton() {
    return (
        <TooltipCustom
            placement={'bottom'}
            text={"Graphs"}
        >
            <Button variant='wegaw-darkblue' href='/dashboard'>
                <GoGraph size={22} />
            </Button>
        </TooltipCustom>
    );
}

function BackToCatchmentSelectionButton() {
    return (
        <TooltipCustom
            placement={'bottom'}
            text={"Catchment Selection"}
        >
            <Button href='/dashboard' variant="wegaw-darkblue" onClick={emptyCatchment}>
                <GrMapLocation size={20} />
            </Button>
        </TooltipCustom>
    );
}

function BackToRasterDownloadsButton() {
    return (
        <TooltipCustom
            placement={'bottom'}
            text={"Raster Downloads"}
        >
            <Button href='/rasters' variant="wegaw-darkblue">
                <FaFileImage size={20} />
            </Button>
        </TooltipCustom>
    );
}

export { BackToGraphsButton, BackToCatchmentSelectionButton, BackToRasterDownloadsButton }