// External
import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { ButtonGroup, DropdownButton, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import Plot from 'react-plotly.js';
import { FaDownload, FaFilter } from 'react-icons/fa';

// Internal
import { DateSelector, DownloadCsvButton, ListOfProductsContext, TooltipCustom } from 'general_components';
import { graphConfiguration, graphLayout, } from './../utils/graphs_aux';
import { create_dropdowns_download_graph, generateName, productResponseToArrays } from 'general_utils/functions';
import DateManager from 'general_utils/datemanager';
import { createRadioToogleButtonsFromArrOfDict, datesDict, createCheckboxToogleButtonsFromArrOfDict } from './../utils/utils';
import { axiosCustom } from 'general_utils/axios';
import { general_config } from 'general_utils/general_configuration';

// Style
import styles from './MultiFilterGraph.module.scss';

function MultiFilterGraph({ catchment }) {

    const datemanager = new DateManager();

    const [radioDateValue, setRadioDateValue] = useState("1m");

    const [modalShow, setModalShow] = useState(false);
    const modalHandleClose = () => setModalShow(false);
    const modalHandleShow = () => setModalShow(true);

    const [productNames, setProductNames] = useState([general_config.default_product]);
    const [apiData, setApiData] = useState([]);
    const [selectedTo, setSelectedTo] = useState(datemanager.ISOStringToDate(new Date().toISOString()));
    const [selectedFrom, setSelectedFrom] = useState(datemanager.ISOStringToDate(datemanager.unixTimestampToDatetime(datemanager.substractDaysToUnixTimestamp(new Date(), 30)).toISOString()));

    useEffect(() => {
        if (selectedTo && selectedFrom) {
            let axiosRequests = [];
            for (let indexProduct in productNames) {
                axiosRequests.push(
                    axiosCustom(`catchments/${catchment}/${productNames[indexProduct].toLowerCase()}-stats/?to=${selectedTo}&from=${selectedFrom}`)
                )
            }

            axios.all(axiosRequests)
                .then(axios.spread((...responses) => {
                    let apiDataObj = []
                    for (let responseIndex in responses) {
                        if (productNames[responseIndex].toLowerCase() === 'pp') {
                            let [dates, values] = productResponseToArrays(responses[responseIndex])
                            apiDataObj.push(
                                {
                                    x: dates,
                                    y: values,
                                    name: productNames[responseIndex].toUpperCase(),
                                    type: 'bar',
                                    marker: { color: general_config.products[productNames[responseIndex].toUpperCase()]?.productColor, size: 5 },
                                    yaxis: (parseInt(responseIndex) === 0 ? 'y' : `y${parseInt(responseIndex) + 1}`),
                                },
                            )

                        } else {
                            let [dates, values] = productResponseToArrays(responses[responseIndex])
                            apiDataObj.push(
                                {
                                    x: dates,
                                    y: values,
                                    name: productNames[responseIndex].toUpperCase(),
                                    type: 'scatter',
                                    mode: (productNames[responseIndex].toLowerCase() === 'ms' ? 'lines+markers' : 'lines'),
                                    line: {
                                        width: 0.75,
                                        dash: (productNames[responseIndex].toLowerCase() === 'ms' ? 'dot' : ''),
                                    },
                                    marker: { color: general_config.products[productNames[responseIndex].toUpperCase()]?.productColor, size: 5 },
                                    yaxis: (parseInt(responseIndex) === 0 ? 'y' : `y${parseInt(responseIndex) + 1}`),
                                },
                            )
                        }
                    }
                    
                    setApiData(apiDataObj)
                }))
        }
    }, [selectedTo, selectedFrom, productNames]);

    let layout = graphLayout(productNames, selectedFrom, selectedTo, apiData);
    let config = graphConfiguration();

    const onRadioToogleButtonClick = (element) => {
        const datemanager = new DateManager();
        let [to, from] = datemanager.getToFrom(element.days)
        setSelectedTo(to);
        setSelectedFrom(from);
    }

    const listOfProducts = useContext(ListOfProductsContext);
    const productsDict = listOfProducts.map(item => {
        return {
            tag: item.toUpperCase(),
            product: item
        };
    });

    const handleChange = (parameter) => {
        setProductNames(parameter);
    };

    return (
        <Row>
            <div className='justify-content-between btn-toolbar'>
                <ButtonGroup className='btn-toolbar mb-2'>
                    {
                        createRadioToogleButtonsFromArrOfDict(
                            datesDict,
                            'datesFilterGraph',
                            radioDateValue,
                            setRadioDateValue,
                            onRadioToogleButtonClick,
                            'graph'
                        )
                    }
                    <TooltipCustom
                        placement={'bottom'}
                        text={"Select custom dates."}>
                        <ToggleButton
                            id={`radio-datesFilterGraph`}
                            type="radio"
                            variant='graph'
                            name="radio"
                            value={'datesFilterGraph'}
                            checked={radioDateValue === 'datesFilterGraph'}
                            onClick={() => {
                                modalHandleShow()
                                setRadioDateValue("datesFilterGraph")
                            }}
                        >
                            <FaFilter />
                        </ToggleButton>
                    </TooltipCustom>
                    <DateSelector
                        modalShow={modalShow}
                        modalHandleClose={modalHandleClose}
                        setSelectedTo={setSelectedTo}
                        setSelectedFrom={setSelectedFrom}
                    />
                </ButtonGroup>
                <ButtonGroup className={styles.alignContentRight}>
                    <ToggleButtonGroup className='btn-toolbar' type="checkbox" onChange={handleChange} value={productNames}>
                        {
                            createCheckboxToogleButtonsFromArrOfDict(
                                productsDict,
                                'datesFilterGraph2'
                            )
                        }
                    </ToggleButtonGroup>
                    <TooltipCustom
                        placement={'left'}
                        text={"Choose a format to download the graph."}>
                        <DropdownButton variant='graph' as={ButtonGroup} title={<FaDownload />}>
                            {
                                create_dropdowns_download_graph(
                                    ['PDF', 'PNG', 'JPEG', 'SVG'],
                                    'filterGraph',
                                    generateName(catchment, productNames, selectedTo, selectedFrom)
                                )
                            }
                        </DropdownButton>
                    </TooltipCustom>
                    <DownloadCsvButton
                        apiData={apiData}
                        catchment={catchment}
                        productNames={productNames}
                        selectedTo={selectedTo}
                        selectedFrom={selectedFrom}
                        buttonVariant={'graph'}
                    />
                </ButtonGroup>
            </div>
            <Plot
                divId='filterGraph'
                data={apiData}
                layout={layout}
                config={config}
            />
        </Row>
    );
};

export default MultiFilterGraph;