// External
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaAngleUp } from 'react-icons/fa';

// Style
import './ScrollToTop.scss';

const scrollToTop = (smooth = false) => {
    if (smooth) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      document.documentElement.scrollTop = 0;
    }
  }

const ScrollToTop = ({
    top = 20,
    smooth = false,
  }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const onScroll = () => {
          setVisible(document.documentElement.scrollTop >= top);
        };
        onScroll();
        document.addEventListener("scroll", onScroll);
        return () => document.removeEventListener("scroll", onScroll);
      }, [top]);

    return(    
        <>
            {visible && (
                <Button variant='wegaw-blue' className={`scroll-to-top`} onClick={scrollToTop}>
                    <FaAngleUp/>
                </Button>
            )}
        </>
    )
}

export default ScrollToTop;