// External
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";

// Internal
import { CsvDownloads, Dashboard, Inflow, Login, Logout, MonitorningDataIssues, NotFound, RasterDownloads } from './pages';
import { CatchmentSelectedCheck, InflowPrivateRoute, PrivateRoute } from './general_components';
import UserInformationStorage from './general_utils/userinformationstorage';

// Style
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.css';
import './style/app.scss';

// Google analytics ID
const GA_MEASUREMENT_ID ='G-MTH2L8WR09';

function App() {

  const [userId, setUserId] = useState(UserInformationStorage.get_user_information('user'));

  useEffect(() => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
    ReactGA.set({ userId: userId});

  }, [userId]);

  return (
    < BrowserRouter>
      <Routes>
        <Route path="" element={<Login setUserId = {setUserId}/>} />
        <Route path="logout" element={<Logout/>}/>
        <Route path="dashboard"
          element={
            <PrivateRoute>
              <Dashboard/>
            </PrivateRoute>
          }
        />
        <Route path="rasters"
          element={
            <PrivateRoute>
              <CatchmentSelectedCheck>
                <RasterDownloads/>
              </CatchmentSelectedCheck>
            </PrivateRoute>
          }
        />
        <Route path="downloads"
          element={
            <PrivateRoute>
              <CsvDownloads/>
            </PrivateRoute>
          }
        />
        <Route path="monitoring"
          element={
            <PrivateRoute>
              <MonitorningDataIssues/>
            </PrivateRoute>
          }
        />
        <Route path="inflow"
          element={
            <PrivateRoute>
              <InflowPrivateRoute>
                <Inflow/>
              </InflowPrivateRoute>
            </PrivateRoute>
          }
        />  
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ BrowserRouter>
  );
}

export default App;