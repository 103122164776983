// External
import { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FaAngleUp } from 'react-icons/fa';

// Internal
import { ProfileSidebar, ScrollToTop, Sidebar } from './';
import wegawWhiteLogo from 'images/wegaw-white-icon.svg';

// Style
import styles from './NavBar.module.scss';

function TopBar({ children }) {

    const [showSidebar, setShowSidebar] = useState(false);
    const [showProfileSidebar, setShowProfileSidebar] = useState(false);
    const handleClose = (setFunction) => setFunction(false);
    const handleShow = (setFunction) => setFunction(true);
    return (
        <>
            <Navbar collapseOnSelect className={styles.navbar_container}>
            <ScrollToTop smooth component={<FaAngleUp/>}/>
                <Nav>
                    <Sidebar
                        handleShow={handleShow}
                        setShowSidebar={setShowSidebar}
                        handleClose={handleClose}
                        showSidebar={showSidebar}
                    />
                    <Navbar.Brand href='https://wegaw.com/' target='_blank' rel="noopener noreferrer">
                        <img
                            src={wegawWhiteLogo}
                            width='75'
                            height='25'
                            className={styles.navbar_horizontal_margin}
                            alt='Wegaw logo'
                        />
                    </Navbar.Brand>
                </Nav>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Nav>
                    <Navbar.Collapse id='responsive-navbar-nav'>
                        <Nav>
                            {children}
                            <ProfileSidebar
                                handleShow={handleShow}
                                setShowProfileSidebar={setShowProfileSidebar}
                                handleClose={handleClose}
                                showProfileSidebar={showProfileSidebar}
                            />
                        </Nav>
                    </Navbar.Collapse>
                </Nav>
            </Navbar>
        </>
    )
}

export default TopBar;