// External
import React, { useEffect, useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { TbZoomInArea } from 'react-icons/tb';

// Internal
import UserInformationStorage from 'general_utils/userinformationstorage';
import { TooltipCustom } from 'general_components';
import { axiosCustom } from 'general_utils/axios';
import { centerMapFromClick, sort } from './utils/utils';

// Style
import styles from './CatchmentsList.module.scss';

function CatchmentsList({ mapRef, setCatchmentGeoJson, APIResponseCatchmentEndpoint, setAPIResponseCatchmentEndpoint }) {

  const maxCharactersInUserStorage = 4000000;

  useEffect(() => {
    const apiResponseCatchmentEndpoint = UserInformationStorage.get_user_information("APIResponseCatchmentEndpoint");
  if (apiResponseCatchmentEndpoint) {
    setAPIResponseCatchmentEndpoint(JSON.parse(apiResponseCatchmentEndpoint));
  } else {
    axiosCustom.get('catchments/')
      .then(res => {
        if (res.data.features.length) {
          setAPIResponseCatchmentEndpoint(res.data.features);
          if (JSON.stringify(res.data.features).length < maxCharactersInUserStorage) {
            UserInformationStorage.set_user_information("APIResponseCatchmentEndpoint", JSON.stringify(res.data.features));
          }
        } else {          
          alert("You have no assigned catchments. If you think this is a mistake, please feel free to contact us at info@wegaw.com");
          setAPIResponseCatchmentEndpoint();
        }
   })
  }
}, [setAPIResponseCatchmentEndpoint])

  const renderElement = (selectedCatchment) => {
    let catchmentName = selectedCatchment.properties.name;
    const getCatchmentByName = (catchmentsGeoJson) => catchmentsGeoJson.filter(catchment => catchment.properties.name === catchmentName);

    return (
      <Row className={styles.catchmentList} key={selectedCatchment.properties.name} >
        <Col className='col-8'>
          <div
            className={`navbar_horizontal_margin ${styles.DropdownItemcatchmentListName}`}
            id={selectedCatchment.properties.name}
            onClick={changeCatchment}
            >
            {selectedCatchment.properties?.display_name}
          </div>
        </Col>
        <Col>
          <TooltipCustom
            placement={'left'}
            text={"Click here to zoom on the catchment."}>
            <button
              onClick={() => {
                centerMapFromClick(getCatchmentByName(APIResponseCatchmentEndpoint), mapRef)
              }}
              className={styles.zoomButton} // Custom button to apply specific style
              >
              <TbZoomInArea size={40} />
            </button>
          </TooltipCustom>
        </Col>
      </Row>
    );
  }
  
  const CustomMenu = React.forwardRef(
    ({ children, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
      if (children) {
        children = children.sort(sort);
        let childrenFiltered = children.filter(
          (child) =>  !value || child.props.children[0].props.children.props.children.toLowerCase().includes(value.toLowerCase())
          )
        return (
          <div
            ref={ref}
            className={styles.catchmentListContainer}
            aria-labelledby={labeledBy}
          >
            <Form.Control
              autoFocus
              className={`mx-3 my-2 ${styles.catchmentListInput}`}
              placeholder="Filter by name..."
              onChange={(e) => {setValue(e.target.value)}}
              value={value}
            />
            <ul className={styles['list-unstyled']}>  
              {childrenFiltered}          
            </ul>
          </div>
        )} else {
          <div
          className={styles.catchmentListContainer}
        >
        </div>
        }
    },
  );

  const changeCatchment = (selectedCatchment) => {
    setCatchmentGeoJson(APIResponseCatchmentEndpoint.filter(catchment => catchment.properties.name === selectedCatchment.target.id));
  }

  return (
      <div className={styles.customMenuContainer}>
        <CustomMenu
          children= {APIResponseCatchmentEndpoint?.map(catchment => renderElement(catchment))}
        />
      </div>
  )
}

export default CatchmentsList;