// External
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Internal
import UserInformationStorage from "general_utils/userinformationstorage";

const loginPage = '/';

// children refers to webpage data
function InflowPrivateRoute({ children }) {

    const navigate = useNavigate();

        const catchmentGeoJson = UserInformationStorage.get_user_information('catchmentGeoJson');
        const valid_catchments = ['test']

        useEffect(() => {

            // useEffect to check if the catchmentGeoJson name is in the valid_catchments array, if not will navegate to login, else will exit
            // the useEffect and will return the children (navigate has to be used inside useEffect)

        if (catchmentGeoJson) {
            const catchmentName = catchmentGeoJson ? JSON.parse(catchmentGeoJson)[0]?.properties?.name : false;
            if (!valid_catchments.includes(catchmentName) || !catchmentName) {
                navigate(loginPage);
            }
        } else {
            navigate(loginPage);
        }

    }, []);

    return children;

};

export default InflowPrivateRoute;