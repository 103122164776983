// External
import React, { useState } from 'react';

// Internal
import { CatchmentSelectionPage, GraphsPage }  from './components';
import UserInformationStorage from 'general_utils/userinformationstorage';

function Dashboard() {

  const [catchmentGeoJson, setCatchmentGeoJson] = useState(JSON.parse(UserInformationStorage.get_user_information('catchmentGeoJson')));

  if (catchmentGeoJson) {
    UserInformationStorage.set_user_information('catchmentGeoJson', JSON.stringify(catchmentGeoJson));
    return (
      <GraphsPage
        catchmentGeoJson={catchmentGeoJson}
        setCatchmentGeoJson={setCatchmentGeoJson}
      />
    );
  }
  else {
    return (
      <CatchmentSelectionPage
        setCatchmentGeoJson={setCatchmentGeoJson}
      />
    )
  }
};

export default Dashboard;