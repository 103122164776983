// External
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';

// Internal
import { TooltipCustom } from 'general_components';
import { axiosCustom } from 'general_utils/axios';
import { 
    createCsvHeaderFromProductsArray, 
    createCsvBodyFromProductsAndDatesArrays, 
    downloadCsv, 
    postUserDownload, 
    productResponseToArrays 
} from 'general_utils/functions';

export const getDownloadsFromApi = () => axiosCustom.get('/downloads/');

export const splitFilename = (file_name) => {
    let fileNameWithoutExtension = file_name.split(".")[0]
    let fileNameSplited = fileNameWithoutExtension.split("__");
    let catchment = fileNameSplited[0].split("_")[1];
    let products = fileNameSplited[1].split("_");
    let [,to, from] = fileNameSplited[2].split("_");
    return [catchment, products, from, to];
};

const onClickAction = (p) => {
    let [catchment, products, from, to] = splitFilename(p);
    let axiosRequests = [];
    let arrayOfValues = [];
    let arrayOfDates = [];
    for (let indexProduct in products) {
        axiosRequests.push(
            axiosCustom(`catchments/${catchment}/${products[indexProduct].toLowerCase()}-stats/?to=${to}&from=${from}`)
        );
    };
    axios.all(axiosRequests)
        .then(axios.spread((...responses) => {
            for (let i in responses) {
                let [dates, values] = productResponseToArrays(responses[i]);
                arrayOfValues.push(values);
                arrayOfDates.push(dates);
            }
            downloadCsv(p, createCsvHeaderFromProductsArray(products), createCsvBodyFromProductsAndDatesArrays(arrayOfValues, arrayOfDates));
            postUserDownload(p)
        }))
}

export const addButtonDownload = (p) => {
    return (
        <TooltipCustom
            placement={'bottom'}
            text={"Download the file."}>
            <Button size="sm" variant='wegaw-darkblue' onClick={() => onClickAction(p)}>
                <FaFileDownload/>
            </Button>
        </TooltipCustom>
    );
};