// External
import React, { useState, useEffect } from 'react';
import { Container, Navbar } from 'react-bootstrap';

// Internal
import { MultiFilterGraph, YearlyComparisonGraph } from './components';
import { ContactParagraph, GraphsRow, NavBar, ListOfProductsContext } from 'general_components';
import { axiosCustom } from 'general_utils/axios';
import { general_config } from 'general_utils/general_configuration';
import { BackToCatchmentSelectionButton, BackToRasterDownloadsButton } from 'general_components/CustomButtons';

// Style
import styles from './GraphsPage.module.scss';

function GraphsPage({ catchmentGeoJson }) {

  const [listOfProducts, setListOfProducts] = useState([general_config.default_product]);

  const catchmentDisplayName = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.display_name : '');
  const catchmentName = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.name : '');
  const catchmentCountry = (catchmentGeoJson.length > 0 ? catchmentGeoJson[0].properties.country : '');
  const catchmentArea = (catchmentGeoJson.length > 0 ? (catchmentGeoJson[0].properties.area > 0 ? '. Area: ' + catchmentGeoJson[0].properties.area + ' km²' : ''):'');

  useEffect(() => {  
    if (catchmentName.length > 0) {
      axiosCustom.get(`catchmentProducts/${catchmentName}`)
      .then(res => {
        setListOfProducts(res.data[0].available_products);
      })
    }
    }, [catchmentName]);

  return (
    <>
      <NavBar>
        <Navbar>
          <strong>{catchmentDisplayName}{`, ${catchmentCountry}`}{` ${catchmentArea}`}</strong>
        </Navbar>
        <BackToCatchmentSelectionButton/>
        <BackToRasterDownloadsButton/>
      </NavBar>
      <Container className={`mt-3 ${styles.graphsContainer}`}>
        <GraphsRow style={{backgroundColor: '#f1f3f4'}}>
          <ListOfProductsContext.Provider value={listOfProducts}>
            <YearlyComparisonGraph
              catchment={catchmentName}
              catchmentGeoJson={catchmentGeoJson}
            />
          </ListOfProductsContext.Provider>
        </GraphsRow>
        <GraphsRow className='mt-3'  style={{backgroundColor: '#f1f3f4'}}>
          <ListOfProductsContext.Provider value={listOfProducts}>
            <MultiFilterGraph
              catchment={catchmentName}
            />
          </ListOfProductsContext.Provider>
        </GraphsRow>
      </Container>
      <ContactParagraph/>
    </>
  )
}

export default GraphsPage;