// External
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Style
import styles from './NotFound.module.scss';

const loginPage = '/';
const dashboardPage = '/dashboard';

function NotFound() {

  return (
    <div className="jumbotron d-flex align-items-center min-vh-100">
      <div className={styles.notFound}>
        <h1 className="mb-3">
          Whoops, it seems we’re lost
        </h1>
        <h4 className="mb-3">
          Don’t panic, we can get you back on track.
          <br />
          You can either click back to return to the previous page or go with one of the following options:
        </h4>
        <Link to={dashboardPage} className={styles.linkStyle}>
          <Button className={`mt-10 ${styles.wegaw_button}`}>
            Dashboard
          </Button>{' '}
        </Link>
        <Link to={loginPage} className={styles.linkStyle}>
          <Button className={styles.wegaw_button}>
            Login
          </Button>
        </Link>
      </div>
    </div>
  )
};

export default NotFound;