// External
import React from "react";
import { Navigate } from "react-router-dom";

// Internal
import UserInformationStorage from "general_utils/userinformationstorage";

const dashboardPage = '/dashboard';

// children refers to webpage data
function CatchmentSelectedCheck({ children }) {
    let catchment = UserInformationStorage.get_user_information('catchmentGeoJson');
    if (catchment) {
        return children;
    }
    else {
        alert("Catchment needs to be selected")
        return <Navigate to={dashboardPage}/>;
    }
}

export default CatchmentSelectedCheck;