// External
import { Container } from 'react-bootstrap';

// Internal
import { createPlaceholders } from 'general_utils/functions';

// Style
import styles from './ContainerOfPlaceholders.module.scss'

function PlaceholdersContainer({ numOfPlaceholders }) {
    return(
        <Container className={styles.placeholder_container}>
            {createPlaceholders(numOfPlaceholders)}
        </Container>
    )
}

export default PlaceholdersContainer;