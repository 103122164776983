// External
import { ToggleButton } from "react-bootstrap";
import DropdownItem from 'react-bootstrap/esm/DropdownItem';

// Internal
import { TooltipCustom } from "general_components";
import UserInformationStorage from "general_utils/userinformationstorage";

export const datesDict = [
    { days: 30, tag: "1m", tooltipText: "Show last month values." },
    { days: 183, tag: "6m", tooltipText: "Show last six months values." },
    { days: 365, tag: "1y", tooltipText: "Show last year values." },
    { days: 5001, tag: "all", tooltipText: "Show all data available." }
]

export const createRadioToogleButtonsFromArrOfDict = (dict, elementName, state, setState, onClickFunction, buttonVariant, tooltipPlacement = 'bottom') => {
    return dict.map((element, idx) => (
        <TooltipCustom
            placement={tooltipPlacement}
            text={element.tooltipText}
            key={element.tag}>
            <ToggleButton
                key={idx}
                id={`radio-${elementName}-${idx}`}
                type="radio"
                variant={buttonVariant}
                name={elementName}
                value={element.tag}
                checked={state === element.tag}
                onClick={() => {
                    onClickFunction(element)
                    setState(element.tag)
                }}
            >
                {element.tag}
            </ToggleButton>
        </TooltipCustom>
    ))
}

export const createCheckboxToogleButtonsFromArrOfDict = (dict, elementName) => {
    return dict.map((element, idx) => (
        <ToggleButton
            key={idx}
            id={`check-${elementName}-${idx}`}
            variant='graph'
            value={element.product}
            size={'lg'}
        >
            {element.tag}
        </ToggleButton>
    ))
}

export const createDropdownItemsFromArrOfDict = (dict, setProduct) => {
    return dict.map((element) => (
        <DropdownItem key={element.tag} onClick={() => {
            setProduct(element.product)
        }}>
            {element.tag}
        </DropdownItem>
    ))
}

export  const removeCatchmentItemsFromUserStorage = () => { 
    UserInformationStorage.remove_user_information('catchmentGeoJson');
  };