// External
import { TbZoomInArea } from 'react-icons/tb';

export const InfoCatchmentSelection = () => {
  return (
    <>
      <p>You can select the catchment rather on the list (use the scroll or the filter to locate it) or on the map.</p>
      <p>To zoom to the catchment, you can click on the symbol {<TbZoomInArea/>}.</p>
    </>
  )
}