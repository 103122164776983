// External
import { Row } from 'react-bootstrap';

// Style
import styles from './GraphsRow.module.scss'

function GraphsRow(props){ // pass classname to wrapper
    return(
        <Row style={props.style} className={`${props.className} ${styles.graphs_row}`}>
            {props.children}
        </Row>
    )
}

export default GraphsRow;