// External
import React, { useRef, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';

// Internal
import CatchmentsList from './components/CatchmentsList/CatchmentsList';
import CatchmentMap from './components/CatchmentsMap/CatchmentsMap';
import { ContactParagraph, NavBar, ScrollToTop, TooltipCustom } from 'general_components';
import { InfoCatchmentSelection } from './utils/utils';

// Style
import styles from './CatchmentSelectionPage.module.scss';

function CatchmentSelectionPage({ setCatchment, setCatchmentGeoJson }) {

    const [APIResponseCatchmentEndpoint, setAPIResponseCatchmentEndpoint] = useState(null);
    const mapRef = useRef();

    return (
        <>
            <ScrollToTop/>
            <NavBar>
                <TooltipCustom
                    placement={'left'}
                    text={
                        <InfoCatchmentSelection/>
                    }
                >
                    <a>
                        <FaInfoCircle size={20} className={`navbar_horizontal_margin ${styles.infoIcon}`}/>
                    </a>
                </TooltipCustom>
            </NavBar>
            <div className={styles.selectionPage}>
                <CatchmentMap
                    mapRef={mapRef}
                    catchmentsGeoJson={APIResponseCatchmentEndpoint}
                    setCatchment={setCatchment}
                    setCatchmentGeoJson={setCatchmentGeoJson}
                />
                <CatchmentsList
                    setCatchment={setCatchment}
                    setCatchmentGeoJson={setCatchmentGeoJson}
                    APIResponseCatchmentEndpoint={APIResponseCatchmentEndpoint}
                    setAPIResponseCatchmentEndpoint={setAPIResponseCatchmentEndpoint}
                    mapRef={mapRef}
                />
            </div>
            <ContactParagraph/>
        </>

    )
}

export default CatchmentSelectionPage;