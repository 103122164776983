import jwt_decode from "jwt-decode";
import { Placeholder } from 'react-bootstrap';
import React from 'react';
import { Dropdown } from 'react-bootstrap';

import { axiosCustom } from './axios';
import DateManager from "./datemanager";
import UserInformationStorage from './userinformationstorage';
import { exportGraph } from 'pages/Dashboard/components/GraphsPage/utils/graphs_aux';

const decodeToken = (token=UserInformationStorage.get_user_information('token')) => jwt_decode(token);

export const postUserDownload = (file_name, user_id=decodeToken().user_id) => {
    axiosCustom({
        method: 'post',
        url: "downloads/",
        data: {
            download_date: DateManager.getCurrentDatetime(),
            download_name: file_name,
            user: user_id,
        }
    });
};

export const downloadCsv = (csv_name, csv_header, csv_body) => {
    let csvContent =  "data:text/csv;charset=utf-8" + csv_header + "\n" + csv_body;
    const encodedUri = encodeURI(csvContent);
    const linkToDOM = document.createElement('a');

    linkToDOM.setAttribute('href', encodedUri);
    linkToDOM.setAttribute('download', csv_name);
    document.body.appendChild(linkToDOM);
    linkToDOM.click();
    linkToDOM.remove();
}

export const generateName = (catchment_name, product_names, _from, to) => {
    
    let companyName = "Wegaw";
    let endSection = '__';
    let elementSplit = '_';

    let fileName = companyName + elementSplit + catchment_name + endSection;

    for (let index in product_names){
        if (index === product_names.length -1) fileName += product_names[index]
        else fileName += product_names[index] + elementSplit; 
    } fileName += endSection

    if (_from === null) _from = DateManager.ISOStringToDate(new Date().toISOString());
    if (to === null) to = DateManager.ISOStringToDate(DateManager.unixTimestampToDatetime(DateManager.substractDaysToUnixTimestamp(new Date(), 30).toISOString()));

    fileName += _from + elementSplit + to ;

    return fileName;
}

export const getBiggerNumberOfArray = (array_of_numbers) => {
    let biggerNumber = -9999;
    for (let index in array_of_numbers){
        if (biggerNumber < array_of_numbers[index]) {
            biggerNumber = array_of_numbers[index]
        }
    }
    return biggerNumber
}

const getArrayOfLengthOfArrays = (array_of_arrays) =>{
    let arrayOfLengths = [];
    for (let index in array_of_arrays) {
        arrayOfLengths.push(array_of_arrays[index].length)
    }
    return arrayOfLengths;
}


export const createCsvHeaderFromProductsArray = (products_names) => {
    let csvHeader = "";
    for (let i in products_names){
        csvHeader = csvHeader + ",dates " + products_names[i] + "," + products_names[i];
    }
    return csvHeader;
}

export const createCsvBodyFromProductsAndDatesArrays = (products_values, products_dates) => {
    let csvBody = [];
        let length_of_arrays = getArrayOfLengthOfArrays(products_values);
        let numMaxRows = getBiggerNumberOfArray(length_of_arrays);
        for (let indexRow = 0; indexRow < numMaxRows; indexRow++) {
            for (let indexProduct in products_values){
                csvBody += (products_dates[indexProduct][indexRow] !== undefined ? products_dates[indexProduct][indexRow] : "") + ", " + (products_values[indexProduct][indexRow] !== undefined ? products_values[indexProduct][indexRow] : "");
                if (indexProduct < products_values.length) csvBody += ", ";
            }   
            csvBody = csvBody.substring(0, csvBody.lastIndexOf(", ")) + "\n"; // remove last coma when end of the row to the add the new line
        }
    return csvBody;
}

export const axiosCall = ({catchment, productName, to = false, from = false}) => {
    let request = "catchments/" + catchment + "/" + productName + "-stats/";
    if (to){
        request += "?to=" + to;
        if(from) request += "&from=" + from;
    } else if (from) request += "?from=" + from;
    return axiosCustom.get(request);
}

export const productResponseToArrays = (response) => {

    let dates = [];
    let values = [];

    response?.data.forEach(e => {
        dates.push(e.date);
        values.push(e.value);
    })

    return [dates, values]
}

export const titleCase = (word) => word[0].toUpperCase() + word.slice(1).toLowerCase();

export const createPlaceholders = (numberOfPlaceholersToAdd) => {
    return [...Array(numberOfPlaceholersToAdd)].map((undefinedValue, index) => {
      return (
        <Placeholder key={index} as='p' animation='wave'>
          <Placeholder xs={12} />
        </Placeholder>
      )
    })
};

export const create_dropdowns_download_graph = (arrayOfFormats, graphId, graphName) => {
    return arrayOfFormats.map((format, index) => {
      return (
        <Dropdown.Item key={index} onClick={() => exportGraph(graphId, graphName, format)}>{format}</Dropdown.Item>
      );
    });
};